<script>
export default {
  name: 'Contact',

  data() {
    return {
      type: null,
    };
  },

  mounted() {
    console.log('Component mounted');
    document.title = "Kontakta Tempus Bildbyrå";
  },


  watch: {
    $route(to, from) {
      this.type = to.meta.data;
    },
  },

  

  created() {
    this.type = this.$route.meta.data;
  },

   methods: {
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'false');
      location.reload()
      // Optionally, you can also update a state variable if needed.
      // this.cookiesAccepted = true;
    }
  }
};
</script>

<template>
  <div class="illustration-info-container">
    <div class="illustration-info">
      <div class="content">
        <div class="header p-4">
          <h1>Kontakta Tempus Bildbyrå</h1>
        </div>
        <br>

        <div class="contact-content-wrapper">
          <div class="contact-text">
        
            <p>Du är varmt välkommen att kontakta oss med allehanda frågor. Endast en bråkdel av vårt bildarkiv är tillgängligt här på webbplatsen, så vi uppmuntrar dig att fråga efter motiv som du saknar.</p>
            <br/>
            <p>
              We are happy to answer questions in English. Wir beantworten gerne Fragen auf Deutsch.
            </p>
            <br/>

            <div class="contact-border-content">
              <div class="boder-contact">
                <p style="margin-bottom: 10px;">Tempus Bildbyrå Aktiebolag <br/>
                  Domarvägen 20<br/>
                  141 62 Huddinge<br/>
                  Sverige
                </p>
         
                <a href="mailto: tempus@tempuspictures.com">tempus@tempuspictures.com</a> 
                <p style="margin-top: 10px;">
                    Organisationsnummer: 559446-4611 <br/>
                    Momsregistreringsnummer: SE559446461101<br/>
                    Säte: Huddinge kommun<br/>
                  </p>

              <div class="contact-social">
                 <a href="https://www.facebook.com/tempus.bildbyra" target="_blank"><img src="@/assets/facebook.png" alt="facebook"></a>
                 <a href="https://www.facebook.com/tempus.bildbyra" target="_blank">Följ Tempus Bildbyrå på Facebook!</a> 
              </div>

              <div class="cookie-open" @click="acceptCookies">
                Ändra cookie-inställningar.
              </div>
              </div>

              
            </div>

           
          </div>

           <div class="contact-image">
            <img src="@/assets/contact.jpg" style="border: 1px solid grey; padding: 5px;">
          </div>
        </div>
        

       
      </div>
      
    </div>
  </div>
</template>


<style lang="scss" scoped>
.illustration-info-container {
  text-align: left;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.05);

  -ms-overflow-style: none; 
    scrollbar-width: none; 

  .illustration-info {
    // width: 75%;
    margin: 0 25%;
    height: 100vh;
    // margin: 0 auto 0;
    background-color: #fff;

    @media only screen and (max-width: 992px){
      margin: 0 20px;
      width: 100%;
      height: 100%;
    }

    @media only screen and (max-width: 600px){
      width: auto;
    }

    // @media screen and (max-width: $bp-tablet) {
    //   width: 100%;
    //   height: 100%;
    // }

    .header {
      span {
        margin: 0 1em;
        cursor: pointer;
      }

      h3 {
        padding: 1em;
      }
    }

    .content {
      padding: 1em;

      h1{
         font-size: 30px;
        font-weight: 500;
        line-height: 36px;
      }

      div {
        padding: 1em;
      }

      .contact-content-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 30px;

        @media screen and (max-width: $bp-tablet){
          flex-direction: column;
          gap: 40px;
        }

        .contact-text{
          width: 70%;
          // width: 50%;
          padding: 0;

          p{
            font-size: 16px;
            font-weight: 500;
            line-height: 29px;
          }

         

          @media screen and (max-width: $bp-tablet) {
            width: 100%;
          }

          .contact-border-content{
            padding: 0;
            border: 1px solid #000;
            width: 75%;

            @media screen and (max-width: $bp-tablet){
              width: 100%;
            }

            .boder-contact{
              a{
                color: #2596be;
                text-decoration: underline;
              }
            }
          }

          .contact-social{
            display: flex;
            flex-direction: row;
            gap: 10px;
            padding: 0;
            margin-top: 20px;

            @media screen and (max-width: $bp-tablet){
              align-items: center;
            }

            img{
              width: 20px;
              height: 20px;
            }
          }

          .cookie-open{
            cursor: pointer;
            padding: 0;
            margin-top: 20px;
             color: #2596be;
                text-decoration: underline;
          }
        }

        .contact-image{
          width: 30%;
          // width: 50%;
          padding: 0;
          display: flex;
          justify-content: center;

          @media screen and (max-width: $bp-tablet){
            width: 100%;
          }

          img{
            height: 250px;

            @media screen and (max-width: $bp-tablet){
              height: 100%;
            }
          }

          @media only screen and (max-width: 600px) {
            width: 100%;
          }
        }
      }

      .about {
        p {
          margin: 0;
          font-size: 0.9em;
          line-height: 1.4em;
        }
      }

      .category {
        div {
          padding: 0;
          margin: 1em 0;

          &:last-of-type {
            margin-bottom: 0;
            border-bottom: none;
          }
        }
      }

      .description {
        background-color: #dfe6e9;
        padding-left: 0;
        padding-right: 0;
      }

      .tags {
        display: block;

        .badge {
          display: inline-block;
          font-size: 0.75em;
          padding: 0.5em 0.75em;
          border-radius: 5px;
          color: #fff;
          margin: 0 0.5em 0.5em 0;
          background-color: #1c1c68;
        }
      }
    }
  }

  .newsletter-input {
    border: 1px solid #2596be;
    border-radius: 0.25rem;
    padding: 0.2em;
  }

  .newsletter-table {
    border: none;

    tr {
      height: 35px;
    }

    td {
      padding: 0.2em;
    }
  }

  .newsletter-button {
    background: #2596be;
    border-radius: 0.25rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 26px;
    cursor: pointer;
  }

}
</style>